import axios from 'axios'
import Vue from 'vue'
import authHeader from './auth-header'
import Config from '../../config'

const serverAddress = `${Config.apiServers.url}`
const SOCKET_SERVER_1_URL = `${Config.gpsServers.server1.url}`

class UserService {
  // eslint-disable-next-line class-methods-use-this
  getInfo() {
    // eslint-disable-next-line no-underscore-dangle
    const promise = axios.post(`${serverAddress}/user/info`, {}, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  updateFirebaseDataIfNeeded(payload) {
    // fUpdated
    // زمانی این مقدار آپدیت می شود
    // که از سمت سرور جواب موفقیت آمیز ارسال شود
    // در کدهای پایینتر مقدار دهی می شود

    // همیشه زمانی فایربیس چک می شود که در حالت پلتفرم
    // مربوط به اپ باشد
    const force = 'force' in payload ? payload.force : false
    if (!(['android', 'ios'].find(e => e === Vue.prototype.$productionPlatform))
        || (!force && localStorage.getItem('fUpdated'))) {
      return
    }

    // جهت فعال یا غیر فعال کردن نوتیفیکشن برای این دستگاه
    // برای خروج مورد نیاز هست
    const enable = 'enable' in payload ? payload.enable : true
    // زمانی که هر دو مقدار داشتند آنگاه برای آپدیت اقدام می کنیم
    const deviceId = localStorage.getItem('fDeviceId')
    const deviceToken = localStorage.getItem('fDeviceToken')
    if (!deviceId || !deviceToken || deviceId === '' || deviceToken === '') {
      return
    }

    axios.post(`${SOCKET_SERVER_1_URL}/firebase`, {
      os: Vue.prototype.$productionPlatform, deviceId, deviceToken, enable,
    },
    { headers: authHeader() }).then(response => {
      if (response.data.status === 'success') {
        localStorage.setItem('fUpdated', Vue.prototype.$functions.time())
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  update(data) {
    const promise = axios.post(`${serverAddress}/user/update`,
      {
        // eslint-disable-next-line no-underscore-dangle
        name: data.name,
        family: data.family,
      }, { headers: authHeader() })

    return promise.then(response => response.data)
  }
}

export default new UserService()
