export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'))
  if (user && 'data' in user && user.data.access_token) {
    // for Node.js Express back-end
    return {
      Authorization: `Bearer ${user.data.access_token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
    }
  }
  return {}
}
