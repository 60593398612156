import Vue from 'vue'

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueI18n from 'vue-i18n'
import './vee-validate'
import VuetifyConfirm from 'vuetify-confirm'
import vuetify from '@/libs/vuetify' // path to vuetify export
import './registerServiceWorker'

import messages from './lang'
import router from './router'
import store from './store'
import App from './App.vue'
import Config from '../config'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import { functions } from '@/functions'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueI18n)

// Composition API
Vue.use(VueCompositionAPI)

// Global vars & functions

// Global functions
Vue.prototype.$functions = functions

// Global selected imeis for view on map
Vue.prototype.$mobileSelectedDevices = functions.getMobileSelectedDevices()

// Platforms = android, iphone, web
Vue.prototype.$productionPlatform = Config.productionPlatform

// Global devices
Vue.prototype.$devices = []
Vue.prototype.$selectDevicePlayBack = 0
Vue.prototype.$selectDeviceTracking = 0
Vue.prototype.$selectDeviceDetail = 0
Vue.prototype.$selectCommand = 0
Vue.prototype.$selectDashboard = 0
Vue.prototype.$selectAlertSetting = 0

// First loaded scripts
Vue.prototype.$pagesInitializeData = {}

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = true

Vue.use(VueI18n)
let defaultLanguage = 'fa'

// بررسی اینکه قبلا چه زبانی را انتخاب کرده است و همان انتخاب شود
const locale = localStorage.getItem('locale')
if (locale in messages) {
  defaultLanguage = localStorage.getItem('locale')
}

export const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages,
})

Vue.use(VuetifyConfirm, {
  vuetify,
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
