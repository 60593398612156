import axios from 'axios'
import authHeader from './auth-header'
import Config from '../../config'

const API_URL = `${Config.apiServers.url}`
const SOCKET_SERVER_1_URL = `${Config.gpsServers.server1.url}`

export class DeviceService {
  // eslint-disable-next-line class-methods-use-this
  getList() {
    const promise = axios.post(`${API_URL}/user/device/list`, {}, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getAll(data) {
    const promise = axios.post(`${API_URL}/admin/device/list`, data, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getAllByUserId(data) {
    const promise = axios.post(`${API_URL}/admin/device/getByUserId`, data, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getAllByResellerId(data) {
    const promise = axios.post(`${API_URL}/admin/device/getByResellerId`, data, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // گرفتن مقادیر lastStatus و notificationSettings ...
  // eslint-disable-next-line class-methods-use-this
  getServerDeviceStatusAndSettings(payload) {
    const promise = axios.post(`${SOCKET_SERVER_1_URL}/device/status-and-settings`, payload, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line consistent-return,class-methods-use-this
  sendCommand(payload) {
    const { imei, commandName } = payload
    const promise = axios.post(`${SOCKET_SERVER_1_URL}/device/command`, {
      imei,
      commandName,
    }, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getPositions(data) {
    const promise = axios.post(`${SOCKET_SERVER_1_URL}/device/position`, data, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getAlarm(data) {
    const promise = axios.post(`${SOCKET_SERVER_1_URL}/device/alarm`, data, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getById(data) {
    const promise = axios.post(`${API_URL}/user/device/byId`, { id: data.id }, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  add(payload) {
    return axios.post(`${API_URL}/admin/device/add`, payload, { headers: authHeader() })
      .then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  addXlm(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}/admin/device/add-list`, payload, { headers: authHeader() })
        .then(response => {
          if (response.data.status === 'success') {
            axios.post(`${SOCKET_SERVER_1_URL}/device/add-list`, response.data.result, { headers: authHeader() })
              .then(res => {
                resolve(res.data)
              })
              .catch(() => reject(new Error('add error.')))
          } else {
            resolve(response.data)
          }

        })
        .catch(() => reject(new Error('add error.')))
    })
  }

  // eslint-disable-next-line class-methods-use-this
  addToUser(payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${SOCKET_SERVER_1_URL}/device`, {
        imei: payload.imei,
        userId: payload.uid,
        resellerId: payload.resellerId,
      }, { headers: authHeader() })
        .then(response => {
          if (response.data.status === 'success') {
            axios.post(`${API_URL}/admin/device/addToUser`, payload, { headers: authHeader() })
              .then(res => resolve(res.data))
              .catch(() => reject(new Error('Confirm error.')))
          } else {
            resolve(response.data)
          }
        })
        .catch(() => reject(new Error('Confirm error.')))
    })
  }

  // eslint-disable-next-line class-methods-use-this
  changeUser(payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${SOCKET_SERVER_1_URL}/device`, {
        imei: payload.imei,
        userId: payload.customerId,
        resellerId: payload.resellerId,
      }, { headers: authHeader() })
        .then(response => {
          if (response.data.status === 'success') {
            axios.post(`${API_URL}/admin/device/changeUser`, {
              imei: payload.imei,
              customerId: payload.customerId,
              resellerId: payload.resellerId,
            }, { headers: authHeader() })
              .then(res => {
                resolve(res.data)
              })
              .catch(() => reject(new Error('Confirm error.')))
          } else {
            resolve(response.data)
          }
        })
        .catch(() => reject(new Error('Confirm error.')))
    })
  }

  // eslint-disable-next-line class-methods-use-this
  changeModel(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}/admin/device/changeModel`, payload, { headers: authHeader() })
        .then(res => {
          resolve(res.data)
        })
        .catch(() => reject(new Error('Confirm error.')))
    })
  }

  // eslint-disable-next-line class-methods-use-this
  changeTitle(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}/user/device/changeTitle`, {
        imei: payload.imei,
        title: payload.title,
        category: payload.category,
      }, { headers: authHeader() })
        .then(res => {
          resolve(res.data)
        })
        .catch(() => reject(new Error('Confirm error.')))
    })
  }

  // eslint-disable-next-line class-methods-use-this
  changeSim(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}/user/device/changeSim`, {
        imei: payload.imei,
        sim: payload.sim,
      }, { headers: authHeader() })
        .then(res => {
          resolve(res.data)
        })
        .catch(() => reject(new Error('Confirm error.')))
    })
  }

  // کنترل شماره imei
  // eslint-disable-next-line class-methods-use-this
  check(payload) {
    return axios
      .post(`${API_URL}/user/device/check`, payload, { headers: authHeader() })
      // eslint-disable-next-line consistent-return
      .then(response => {
        if (response.data.status === 'success') {
          console.log('Device checked: ', response)
        }
        return response.data
      })
  }

  // ارسال پیامک تنظیم به دستگاه ردیاب
  // eslint-disable-next-line class-methods-use-this
  configure(payload) {
    return axios
      .post(`${API_URL}/user/device/configure`, payload, { headers: authHeader() })
      .then(response => {
        if (response.data.status === 'success') {
          console.log('Configure checked: ', response)
        }
        return response.data
      })
  }

  // بررسی پاسخ سیم کارت
  configureStatus(payload) {
    return axios
      .post(`${API_URL}/user/device/configure-status`, payload, {
        headers: authHeader(),
        timeout: 4400,
      })
      .then(response => {
        if (response.data.status === 'success') {
          console.log('Configure status checked: ', response)
        }
        return response.data
      })
  }

  // eslint-disable-next-line class-methods-use-this
  confirm(payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${SOCKET_SERVER_1_URL}/device`, {
        imei: payload.imei,
        userId: payload.userId,
        resellerId: payload.resellerId,
        title: payload.title,
        category: payload.category,
      }, { headers: authHeader() })
        .then(response => {
          if (response.data.status === 'success') {
            axios.put(`${API_URL}/user/device`, {
              imei: payload.imei,
              sim: payload.sim,
              title: payload.title,
              category: payload.category,
            }, { headers: authHeader() })
              .then(res => {
                resolve(res.data)
              })
              .catch(() => reject(new Error('Confirm error.')))
          } else {
            resolve(response.data)
          }
        })
        .catch(() => reject(new Error('Confirm error.')))
    })
  }

  // eslint-disable-next-line class-methods-use-this
  notificationSettings(data) {
    const promise = axios.put(`${SOCKET_SERVER_1_URL}/device/notification/settings`,
      data, { headers: authHeader() })

    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  delete(payload) {
    console.log(payload.imei)
    return new Promise((resolve, reject) => {
      axios.post(`${SOCKET_SERVER_1_URL}/device/delete`, {
        imei: payload.imei,
      }, { headers: authHeader() })
        .then(response => {
          if (response.data.status === 'success') {
            axios.post(`${API_URL}/admin/device/delete`,
              {
                imei: payload.imei,
              },
              { headers: authHeader() })
              .then(res => {
                resolve(res.data)
              }).catch(() => reject(new Error('delete error.')))
          } else {
            resolve(response.data)
          }
        }).catch(() => reject(new Error('delete error.')))
    })
  }
}
export default new DeviceService()
