// eslint-disable-next-line import/named,import/no-named-as-default
import AuthService from '../../services/auth.service'

const user = JSON.parse(localStorage.getItem('user'))

const verifyLogin = JSON.parse(localStorage.getItem('verifyLogin'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

// eslint-disable-next-line import/prefer-default-export
export default {
  namespaced: true,
  state: initialState,
  verifyLogin,
  actions: {
    // eslint-disable-next-line no-shadow
    login({ commit }, user) {
      return AuthService.login(user).then(
        // eslint-disable-next-line no-shadow
        // مقدار data بازگردانده میشود
        responseData => {
          if (responseData.code === '1') {
            commit('loginVerifySuccess', responseData)
            return Promise.resolve(responseData)
          }
          commit('loginVerifyFailure')
          return Promise.reject(responseData.message)
        },
        error => {
          commit('loginVerifyFailure')
          return Promise.reject(error)
        },
      )
    },
    logout({ commit }) {
      commit('logout')
      AuthService.logout()
    },
    // eslint-disable-next-line no-shadow
    register({ commit }, user) {
      return AuthService.register(user).then(
        responseData => {
          if (responseData.code === '1') {
            commit('registerSuccess')
            return Promise.resolve(responseData)
          }
          commit('registerFailure')
          return Promise.reject(responseData.message)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        },
      )
    },
    // eslint-disable-next-line no-shadow
    registerVerify({ commit }, payload) {
      return AuthService.registerVerify(payload).then(
        responseData => {
          if (responseData.success) {
            commit('registerSuccess')
            return Promise.resolve(responseData)
          }
          commit('registerFailure')
          return Promise.reject(responseData.message)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        },
      )
    },
    // eslint-disable-next-line no-shadow
    loginVerify({ commit }, payload) {
      return AuthService.loginVerify(verifyLogin, payload).then(
        responseData => {
          if (responseData.success) {
            commit('loginSuccess')
            return Promise.resolve(responseData)
          }
          commit('loginFailure')
          return Promise.reject(responseData.message)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        },
      )
    },
  },
  mutations: {
    // eslint-disable-next-line no-shadow
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
    loginVerifySuccess(state) {
      state.status.loggedIn = false
    },
    loginVerifyFailure(state) {
      state.status.loggedIn = false
    },
  },
}
