import axios from 'axios'
import Config from '../../config'
import authHeader from '@/services/auth-header'
import UserService from '@/services/user.service'
import Vue from 'vue'
import qs from 'qs'


const API_URL = `${Config.apiServers.url}`

export class AuthService {
  // eslint-disable-next-line class-methods-use-this
  login(user) {
    return axios
      .post(`${API_URL}/wp-json/digits/v1/send_otp`, qs.stringify(user),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      })
    // eslint-disable-next-line consistent-return
      .then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  loginVerify(verifyLogin, payload) {
    return axios
      .post(`${API_URL}/wp-json/digits/v1/login_user`, qs.stringify(payload),
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/x-www-form-urlencoded',
          },
        })
      .then(response => {
        if (response.data.success) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
      })
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    localStorage.removeItem('user')
    UserService.updateFirebaseDataIfNeeded({ force: true, enable: false })
    window.location.reload(true)
  }

  // eslint-disable-next-line class-methods-use-this
  register(user) {
    return axios
      .post(`${API_URL}/auth/register`, {
        name: user.name,
        family: user.family,
        mobile: user.mobile,
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        },
      })
      // eslint-disable-next-line consistent-return
      .then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  registerVerify(payload) {
    return axios
      .post(`${API_URL}/wp-json/digits/v1/one_click`, qs.stringify(payload),
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/x-www-form-urlencoded',
          },
        })
      .then(response => {
        if (response.data.success) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
      })
  }
}

export default new AuthService()
