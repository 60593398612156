module.exports = {
  productionPlatform: 'android',
  gpsServers: {
    server1: {
      url: 'https://oskooei.com/ideayar01',
      port: 5000,
    },
  },
  apiServers: {
    url: 'https://oskooei.com/ideayar01',
    port: 3000,
  },
  isCreator: false,
}
