import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUserData, isUserLoggedIn } from '@/auth/utils'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/user/UsersView.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'پروفایل',
            active: true,
          },
        ],
      },
    },

    /// ///////////////////////  A U T H
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register-verify',
      name: 'register-verify',
      component: () => import('@/views/authentication/Register-Verify.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/index.html',
      redirect: '/',
    },
    {
      path: '*',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  Vue.prototype.pathname = to.path
  if (to.path === '/login') {
    if (isUserLoggedIn()) {
      router.push({ path: '/' })
    }
    return next()
  }
  if (to.path === '/register') {
    if (isUserLoggedIn()) {
      router.push({ path: '/' })
    }
    return next()
  }
  if (to.path === '/register-verify') {
    if (isUserLoggedIn()) {
      router.push({ path: '/' })
    }
    return next()
  }
  if (to.path === '/login-verify') {
    if (isUserLoggedIn()) {
      router.push({ path: '/' })
    }
    return next()
  }

  if (!isUserLoggedIn()) {
    router.push({ path: '/login' })
  }
  return next()
})

export default router
