<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses, altBackground ? 'nodiplay' : ''] "
  >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BCard, BCardBody, BButton, BFormGroup, BFormInput, BForm, BInputGroup, BSpinner,
} from 'bootstrap-vue'

import { useWindowSize, useCssVar } from '@vueuse/core'

import Vue from 'vue'
import store from '@/store'
import { isUserLoggedIn } from '@/auth/utils'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    BSpinner,
    BFormGroup,
    BCard,
    BCardBody,
    BButton,
    BFormInput,
    BForm,
    BInputGroup,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  data() {
    return {
      isUserLoggedIn: isUserLoggedIn(),
      timerCount: 5,
      installBtn: 'none',
      installer: undefined,
      Platform: Vue.prototype.$productionPlatform,
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    altBackground() {
      const path = this.$route.path.split('/')
      return path[path.length - 1].toLowerCase() === ''
    },
  },

  watch: {


  },

  mounted() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('src/service-worker.js').then(reg => {
        console.log(`Registration successful, scope: ${reg.scope}`)
      })
        .catch(err => {
          console.log(err)
        })
    }
  },
  created() {
    let installPrompt

    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      installPrompt = e
      this.installBtn = 'block'
    })

    this.installer = () => {
      this.installBtn = 'none'
      installPrompt.prompt()
      installPrompt.userChoice.then(result => {
        if (result.outcome === 'accepted') {
          console.log('Install accepted!')
        } else {
          console.log('Install denied!')
        }
      })
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    // document.documentElement.setAttribute('dir', 'rtl')
  },
  setup() {

    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })
    return {
      skinClasses,
    }
  },
  methods: {
    getDeviceInfo(imei) {
      for (let i = 0; i <= Vue.prototype.$devices.length - 1; i += 1) {
        if (Vue.prototype.$devices[i].imei === imei) {
          Vue.prototype.$devices[i].$index = i
          return Vue.prototype.$devices[i]
        }
      }
      return null
    },
  },
}
</script>
