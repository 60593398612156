import Vue from 'vue'
import momentTZ from 'moment-timezone'
import TimeAgo from 'javascript-time-ago'
import fa from 'javascript-time-ago/locale/fa.json'
import moment from 'jalali-moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import axios from 'axios'
import store from '@/store'
import deviceService from '@/services/device.service'

TimeAgo.addLocale(fa)
TimeAgo.addDefaultLocale(fa)

export const functions = {
  isMobile() {
    const currentBreakpointValue = store.getters['app/currentBreakPoint']
    if (currentBreakpointValue === 'xs' || currentBreakpointValue === 'sm') {
      return true
    }
    return false
  },
  // Array of imeis ["1236520...", ...]
  getMobileSelectedDevices() {
    const data = localStorage.getItem('mobileSelectedDevices')
    if (data) {
      return JSON.parse(data)
    }
    return []
  },
  setMobileSelectedDevices(arr) {
    localStorage.setItem('mobileSelectedDevices', JSON.stringify(arr))
    Vue.prototype.$mobileSelectedDevices = arr
  },
  // این تابع بررسی میکند که در صورت ورود با موبایل آیا imei ورودی جز موارد انتخابی
  // هست یا خیر که در صورت نبودن false بر میگرداند
  // برای حالت دسکتاپ همیشه true است
  checkImeiInSelectedList(imei) {
    if (this.isMobile()) {
      if (Vue.prototype.$mobileSelectedDevices.indexOf(imei) > -1) {
        return true
      }
      return false
    }
    return true
  },
  getMapLimit() {
    if (this.isMobile()) {
      return 30
    }
    return 99999999
  },
  time() {
    const now = momentTZ.tz(new Date(), 'Asia/Tehran')
    return Math.ceil(now / 1000)
  },
  timeSince(unixtime) {
    if (typeof unixtime === 'number' && unixtime > 1000000000) {
      // eslint-disable-next-line no-param-reassign
   //   if(new Date() < unixtime * 1000) unixtime = Date() / 1000
      const timeAgo = new TimeAgo('fa-IR')
      return timeAgo.format((unixtime - 1) * 1000, 'round')
    }
    return 'مدتی پیش'
  },
  unixToPersianDate(unixtime) {
    return moment(unixtime * 1000)
      .locale('fa')
      .format('YYYY/M/D  H:mm:ss')
  },
  unixToPersianDateS(unixtime) {
    return moment(unixtime * 1000)
      .locale('fa')
      .format('YYYY/M/D')
  },
  unixToPersianTime(unixtime) {
    return moment(unixtime * 1000)
      .locale('fa')
      .format('h:m:s')
  },
  todayTimes() {
    const start = new Date(this.time() * 1000)
    start.setHours(0, 0, 0, 0)

    const end = new Date()
    end.setHours(23, 59, 59, 0)

    return {
      start: start / 1000,
      end: end / 1000,
    }
  },
  lastDaysTimes(day) {
    const todayTimes = this.todayTimes()
    return {
      start: moment(todayTimes.start * 1000 - (day * 24 * 3600 * 1000))
        .unix(),
      end: todayTimes.end,
    }
  },
  fixNumbers: str => {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g]
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]
    if (typeof str === 'string') {
      for (let i = 0; i < 10; i++) {
        // eslint-disable-next-line no-param-reassign
        str = str.replaceAll(persianNumbers[i], i)
          .replaceAll(arabicNumbers[i], i)
      }
    }
    return str
  },
  toFaNumbers: str => {
    const persianNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹']
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]
    if (typeof str === 'string') {
      for (let i = 0; i < 10; i++) {
        // eslint-disable-next-line no-param-reassign
        str = str.replaceAll(i, persianNumbers[i])
          .replaceAll(arabicNumbers[i], persianNumbers[i])
      }
    }
    return str
  },
  getDeviceIndex(imei) {
    for (let i = 0; i <= Vue.prototype.$devices.length - 1; i += 1) {
      if (Vue.prototype.$devices[i].imei === imei) {
        return i
      }
    }
    return null
  },
  getDeviceInfo(imei) {
    for (let i = 0; i <= Vue.prototype.$devices.length - 1; i += 1) {
      if (Vue.prototype.$devices[i].imei === imei) {
        Vue.prototype.$devices[i].$index = i
        return Vue.prototype.$devices[i]
      }
    }
    return null
  },
  fixDeviceInfo(device) {
    let data = device
    if (!data) {
      data = {
        userId: {
          name: '',
          family: '',
        },
        serverInfo: { servertime: 0 },
      }
    }
    if (!('userId' in data)) {
      data.userId = {
        name: '',
        family: '',
      }
    }
    if (!('serverInfo' in data)) {
      data.serverInfo = { servertime: 0 }
    }
    if (!('serverStatus' in data)) {
      data.serverStatus = { gsmSigStrength: 0, voltageLevel: 0, terminalInfo: { ignition: false, charging: false, gpsTracking: false } }
    }
    return data
  },
  searchInDevices(q) {
    let devices
    if (q && Vue.prototype.$devices.length > 0) {
      devices = Vue.prototype.$devices.filter(item => q.toLowerCase()
        .split(' ')
        .every(v => item.imei.toLowerCase()
          .includes(v) || item.title.toLowerCase()
          .includes(v)))
    } else {
      devices = Vue.prototype.$devices
    }
    return devices
  },
  checkExpire(device) {
    const todey = moment(this.time() * 1000)
      .locale('fa')
      .format('YYYYMMDD')

    if (device.expireTime) {
      if (device.expireTime.replaceAll('/', '') > todey) {
        return true
      }
    } else {
      return true
    }
    return false
  },
  fillDevices() {
    // در این حالت باید حالت انتخابی برداشته شده
    // و مبنای نمایش بر اساس کلیک بر روی هر یک از
    // مارکرها باشد
    Vue.prototype.$mobileSelectedDevices = this.getMobileSelectedDevices()

    return deviceService.getList()
      .then(
        res => {
          if (res.status === 'success') {
            if ('result' in res && 'data' in res.result) {
              Vue.prototype.$devices = res.result.data
              // در صورتی که لیست پیشفرض انتخاب نشده بود در حالت موبایل
              // اولین دستگاه بعنوان پیشفرض انتخابی در نظر گرفته می شود
              if (this.isMobile() && Vue.prototype.$mobileSelectedDevices.length <= 0) {
                this.setMobileSelectedDevices([Vue.prototype.$devices[0].imei])
                // this.checkSelectedDevices()
              }

              const imeis = []
              for (let i = 0; i <= Vue.prototype.$devices.length - 1; i += 1) {
                // پیشفرض همه غیر فعال هستند
                Vue.prototype.$devices[i].onlineStatus = 'offlineSymbol'

                if (this.checkExpire(Vue.prototype.$devices[i])) {
                  Vue.prototype.$devices[i].expire = false
                } else {
                  Vue.prototype.$devices[i].expire = true
                }
                imeis.push(Vue.prototype.$devices[i].imei)
              }

              // در این وضعیت از رم سرور خوانده می شود و دیتابیس درگیر نمی شود برای سرعت بیشتر
              return deviceService.getPositions({
                imeis,
                fromDate: 0,
                toDate: 0,
                limit: 1,
                sortItems: [{
                  name: 'servertime',
                  desc: true,
                }],
              })
                .then(
                  resp => {
                    if (resp.status === 'success') {
                      if ('result' in resp) {
                        for (const imei in resp.result) {
                          if (resp.result[imei].length > 0) {
                            if (resp.result[imei][0].lastStatus) {
                              Vue.prototype.$devices[this.getDeviceIndex(imei)].serverStatus = resp.result[imei][0].lastStatus
                            }
                            // eslint-disable-next-line no-param-reassign
                            delete resp.result[imei][0].lastStatus

                            // eslint-disable-next-line prefer-destructuring
                            Vue.prototype.$devices[this.getDeviceIndex(imei)].serverInfo = resp.result[imei][0]
                          }
                        }
                      }
                    }
                  },
                  error => {
                    this.message = (error.response && error.response.data)
                      || error.message
                      || error.toString()
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'پیام',
                        text: this.message,
                        icon: 'XIcon',
                        variant: 'danger',
                      },
                    })
                  },
                )
            }
          }
        },
        error => {
          this.message = (error.response && error.response.data)
            || error.message
            || error.toString()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'پیام',
              text: this.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        },
      )
  },
  getFullAddress(latlon) {
    return new Promise((resolve, reject) => {
      axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latlon.latitude}&lon=${latlon.longitude}`, { headers: { 'Accept-Language': 'fa' } })
        .then(data => {
          console.log(data)
          let address = ' '
          if (data.data.address.province !== undefined) {
            address += `${data.data.address.province}، `
          }
          if (data.data.address.county !== undefined) {
            address += `${data.data.address.county}، `
          }
          if (data.data.address.city !== undefined) {
            address += `${data.data.address.city}، `
          }
          if (data.data.address.town !== undefined) {
            address += `${data.data.address.town}، `
          }
          if (data.data.address.suburb !== undefined) {
            address += `${data.data.address.suburb}، `
          }
          if (data.data.address.neighbourhood !== undefined) {
            address += `${data.data.address.neighbourhood}، `
          }
          if (data.data.address.residential !== undefined) {
            address += `${data.data.address.residential}، `
          }
          if (data.data.address.road !== undefined) {
            address += `${data.data.address.road}، `
          }
          resolve(address)
        }).catch(err => {
          reject(err)
        })
    })
  },
  updateAddress(latlon) {
    return new Promise((resolve, reject) => {
      axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latlon.latitude}&lon=${latlon.longitude}`, { headers: { 'Accept-Language': 'fa' } })
        .then(data => {
          if (data.data.address.city === undefined) {
            resolve(`${data.data.address.town}، ${data.data.address.road}`)
          } else {
            resolve(`${data.data.address.city}، ${data.data.address.road}`)
          }
        }).catch(err => {
          reject(err)
        })
    })
  },
  getStatusMovement(imei) {
    const deviceInfo = this.getDeviceInfo(imei)
    if (deviceInfo == null) return 0
    if (deviceInfo.onlineStatus !== 'onlineSymbol') {
      return 'offline'
    }
    if (deviceInfo.serverInfo.speed !== 0) {
      return 'move'
    }
    if (deviceInfo.serverStatus.terminalInfo.ignition && deviceInfo.serverInfo.speed === 0) {
      return 'on'
    }
    return 'stop'
  },
  deviceServerSocketReconnect(callback) {
    Vue.prototype.$socket.disconnect(true)
    setTimeout(async () => {
      await Vue.prototype.$socket.connect()
      callback()
    }, 500)
  },
}
